<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon> <span class="primary--text">{{$t('caption.medalList')}}</span>
      <v-spacer></v-spacer>
      <v-btn 
        class="primary" 
        small
        @click.stop="dialog = true"
      >
        <v-icon left>{{icons.mdiPlusThick}}</v-icon> New Medal
      </v-btn>
    </v-card-title>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-icon left>{{icons.mdiAccount}}</v-icon> {{ dialogTitle }}
        </v-card-title>
        <br />
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  :label="$t('text.medalNameKh')"
                  :append-icon="icons.mdiAccount"
                  dense
                  v-model="medalType.medalTypeKh"
                  required
                ></v-text-field>  
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  :label="$t('text.medalNameEn')"
                  :append-icon="icons.mdiAccount"
                  v-model="medalType.medalTypeEn"
                  dense
                ></v-text-field>  
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  :items="medalTypeList"
                  item-value="id"
                  :label="$t('select.type')"
                  v-model="medalType.parent"
                  single-line
                  dense
                >
                  <template v-slot:selection="data">
                    <td v-if="$t('lang') == 'KH'">
                      <v-chip color="primary">{{data.item.medalTypeKh}}</v-chip>
                    </td>
                    <td v-else>
                      <v-chip color="primary">{{data.item.medalTypeEn}}</v-chip>
                    </td>               
                  </template>
                  <template v-slot:item="data">
                    <td v-if="$t('lang') == 'KH'">
                      {{data.item.medalTypeKh}}
                    </td>
                    <td v-else>
                      {{data.item.medalTypeEn}}
                    </td>                  
                  </template>
                </v-select>
              </v-col>           
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            @click="save"
          >
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> {{$t('button.save')}}
          </v-btn>
          <v-btn
            class="secondary"
            @click="dialog = false"
          >
            <v-icon left>{{icons.mdiCancel}}</v-icon> Cancel
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="4"                  
        >
          <v-text-field
            :prepend-inner-icon="icons.mdiMagnify"
            rounded
            single-line
            outlined
            dense
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
        <v-data-table
          :headers="headers"
          :items="medals"
          :search="search"
        >
          <template #[`item.parent`]="{item}">
            <span v-if="item.parent"> 
            {{getMedalTypeName(item.parent)[0].medalTypeKh}}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-2" @click="editItem(item)">
              {{icons.mdiPencil}}
            </v-icon>
            <v-icon medium @click="deleteItem(item)">
              {{icons.mdiDelete}}
            </v-icon>
          </template>
        </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil, mdiDelete, mdiContentSave, mdiCancel, mdiEye, mdiMagnify, mdiPlusThick } from '@mdi/js';
import systemService from '@/services/service.system';

export default {
  name: 'position-list',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiContentSave,
        mdiCancel,
        mdiEye,
        mdiMagnify,
        mdiPlusThick,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Medal Name (KH)', value: 'medalTypeKh' },
        { text: 'Medal Name (EN) ', value: 'medalTypeEn' },
        { text: 'Medal Type', value: 'parent' },
        { text: 'ACTION', value: 'actions', sortable: false },
      ],
      medalTypeList: [],
      medals: [],
      medalType: {},
      dialog: false,
      dialogDelete: false,
      search: '',
      resultMessage: '',
      editedIndex: -1,
    };
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? this.$t('menu.medalCreation') : this.$t('caption.editMedal');
    },
    selectedItems: {
      get() {
        return this.value;
      },
      set(item) {
        this.chosenItems.push(item);
        this.$emit('input', item);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    systemService.getMedalTypeByParent(0).then(response => {
      this.medalTypeList = response.data;
    });

    systemService.getMedalTypeAll().then(response => {
      this.medals = response.data;
    });
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.medalTypeList.indexOf(item);
      this.medalType = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      /*
      alert(JSON.stringify(item))
      this.editedIndex = this.users.indexOf(item);
      this.user = Object.assign({}, item);
      this.dialogDelete = true;
       ----*/
      this.$confirm({
        message: 'Do you want to delete ' + item.nameKh + '?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            systemService.deletePositionById(item.id).then(
              () => {
                const index = this.medalTypeList.indexOf(item);
                this.medalTypeList.splice(index, 1);
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: 'Position has been deleted',
                });
              },
              error => {
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'error',
                  text: error.response.data,
                });
              },
            );
          }
        },
      });
    },
    save() {
      if (!this.$refs.form.validate()) return;
      systemService.insertMedalType(this.medalType).then(
        response => {
          if (response.data) {
            if (this.editedIndex == -1) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.addedPosition'),
              });

              this.medals.push(response.data);
            } else {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.updatedPosition'),
              });
              Object.assign(this.medals[this.editedIndex], response.data);
            }
            this.dialog = false;
          }
        },
        error => {
          this.resultMessage = error.response.data;
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage,
          });
        },
      );
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.medalType = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.medalType = { roles: [], isActive: true };
      });
    },
    getMedalTypeName(parent) {
      return this.medalTypeList.filter(function(elem) {
        //  console.log(elem);
        return elem.id === parent;
      });
    },
  },
};
</script>
